﻿package com.thwackstudio.components

import androidx.compose.runtime.Composable
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px


@Composable
fun ColumnScope.header() {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(200.px)
            .weight(1)
            .backgroundColor(Res.Colors.BG_BLACK), 
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            Res.Images.THWACK_STUDIO_LOGO, modifier = Modifier
                .fillMaxHeight(50.percent)
                .width(auto = auto)
                .aspectRatio(399f / 162f)
        )
//        Column(
//            modifier = Modifier
//                .fillMaxWidth()
//                .height(300.px)
//                .backgroundColor(Res.Colors.BG_LIGHT)
//        ) {
//            Link("/?t=test", "Say goodbye...")
//        }
    }
}