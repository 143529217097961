﻿package com.thwackstudio.components

import androidx.compose.runtime.Composable
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaFaceSmile
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun ColumnScope.footer() {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(auto = auto)
            .padding(all = 20.px)
            .color(Res.Colors.FONT_ORANGE_BRIGHT)
            .fontSize(20.px)
            .fontWeight(600)
            .backgroundColor(Res.Colors.BG_LIGHT)
            .weight(1),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
            Row(
                modifier = Modifier.margin(10.px).fillMaxWidth()
            ) {
                Text("Created by Stefan Wyszyński in Kotlin Kobweb (KMP compose)")
                Text(" ")
                FaFaceSmile()
            }
    }
}