package com.thwackstudio.pages

import androidx.compose.runtime.Composable
import com.thwackstudio.components.UserProfile
import com.thwackstudio.components.footer
import com.thwackstudio.components.header
import com.thwackstudio.components.menuGridItem
import com.thwackstudio.utils.Res
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Canvas2d
import com.varabyte.kobweb.silk.components.graphics.ONE_FRAME_MS_60_FPS
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.theme.colors.palette.*
import com.varabyte.kobweb.silk.theme.colors.shifted
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.CanvasLineCap
import org.w3c.dom.CanvasState
import org.w3c.dom.ROUND
import kotlin.js.Date
import kotlin.math.PI

@Page
@Composable
fun Index() {
    // JSON.parse<CC>("responseText")
    val path = rememberPageContext() // Path updated when browser URL changes
    var postId = ""
    if (path.route.params.containsKey("t")) {
        postId = path.route.params.getValue("t")
    }
    mainMenu(path, postId)
}

@Composable
fun mainMenu(path: PageContext, postId: String) {
    Row(modifier = Modifier.fillMaxSize()) {
        UserProfile(Modifier.weight(0).fillMaxHeight())

        Column(modifier = Modifier.fillMaxSize()) {
            header()
            Column(modifier = Modifier.height(auto = auto).fillMaxWidth().weight(1)) {
                when (postId) {
                    "" -> mainPanel(path)
                    "0" -> ItemDescription(items[0])
                }
            }
            footer()
//            Clock()
        }
    }
}

@Composable
fun ColumnScope.mainPanel(path: PageContext) {
    SimpleGrid(
        numColumns = numColumns(3, sm = 1, md = 2, lg = 3, xl = 3),
        modifier = Modifier
            .alignContent(AlignContent.Stretch)
            .columnGap(20.px)
            .fillMaxWidth()
            .weight(1f)
            .rowGap(20.px)
            .backgroundColor(Res.Colors.BG_WHITE)
            .boxShadow(
                color = Colors.Black.copy(alpha = 10),
                blurRadius = 50.px,
                spreadRadius = 50.px
            )
            .padding(all = 20.px)
//            .styleModifier {
////                property("pointer-events", "none")
//            }
    ) {
        for (item in items) {
            menuGridItem(path, item)
        }
    }
}

fun CanvasState.save(block: () -> Unit) {
    save()
    block()
    restore()
}

@Composable
private fun Clock() {
    // We technically only need to update once per second, but let's be a bit more aggressive to capture color
    // changes faster if they happen
    Canvas2d(300, 300, minDeltaMs = ONE_FRAME_MS_60_FPS * 5) {
        // Adapted from: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Basic_animations#an_animated_clock
        val date = Date()
        val r = 150.0

        // Let's be a little lazy and use some colors from the palette which is already color mode aware
        val palette = colorMode.toPalette()
        val colorBorder = palette.button.pressed
        val colorTicks = palette.color
        val colorHourHand = palette.link.default
        val colorMinuteHand = palette.link.default.shifted(colorMode, byPercent = 0.4f)
        val colorSecondHand = if (colorMode.isDark) Colors.HotPink else Colors.Red
        var width1 = ctx.canvas.width
        var height1 = ctx.canvas.height
//        ctx.save {

            ctx.strokeStyle = colorTicks
            ctx.fillStyle = palette.background

            ctx.clearRect(0.0, 0.0, width1.toDouble(), height.toDouble())
            ctx.lineWidth = 8.0
            ctx.lineCap = CanvasLineCap.ROUND
//            ctx.save {

                for (i in 0 until width1) {

//                    ctx.beginPath()
                    ctx.moveTo(i.toDouble(), 0.0)
                    ctx.lineTo(i.toDouble(), i.toDouble())
                    ctx.stroke()
                }
//            }
            ctx.fillText(height1.toString(), 0.0, 0.0)
            ctx.fillText(width1.toString(), 0.0, 0.0)

//        }
//         {
//            ctx.strokeStyle = colorTicks
//            ctx.fillStyle = paletqte.background
//
//            ctx.clearRect(0.0, 0.0, width.toDouble(), height.toDouble())
//            ctx.translate(r, r)
//            ctx.rotate(-PI / 2)
//            ctx.lineWidth = 8.0
//            ctx.lineCap = CanvasLineCap.ROUND
//
//            // Hour marks
//            ctx.save {
//                for (i in 0 until 12) {
//                    ctx.beginPath()
//                    ctx.rotate(PI / 6)
//                    ctx.moveTo(100.0, 0.0)
//                    ctx.lineTo(120.0, 0.0)
//                    ctx.stroke()
//                }
//            }
//
//            // Minute marks
//            ctx.save {
//                ctx.lineWidth = 5.0
//                for (i in 0 until 60) {
//                    if (i % 5 != 0) {
//                        ctx.beginPath()
//                        ctx.moveTo(117.0, 0.0)
//                        ctx.lineTo(120.0, 0.0)
//                        ctx.stroke()
//                    }
//                    ctx.rotate(PI / 30)
//                }
//            }
//
//            val sec = date.getSeconds()
//            val min = date.getMinutes()
//            val hr = date.getHours() % 12
//
//            // Hour hand
//            ctx.save {
//                ctx.strokeStyle = colorHourHand
//                ctx.rotate(hr * (PI / 6) + (PI / 360) * min + (PI / 21600) * sec)
//                ctx.lineWidth = 14.0
//                ctx.beginPath()
//                ctx.moveTo(-20.0, 0.0)
//                ctx.lineTo(80.0, 0.0)
//                ctx.stroke()
//            }
//
//            // write Minutes
//            ctx.save {
//                ctx.strokeStyle = colorMinuteHand
//                ctx.rotate((PI / 30) * min + (PI / 1800) * sec)
//                ctx.lineWidth = 10.0
//                ctx.beginPath()
//                ctx.moveTo(-28.0, 0.0)
//                ctx.lineTo(112.0, 0.0)
//                ctx.stroke()
//            }
//
//            // Write seconds
//            ctx.save {
//                ctx.rotate(sec * PI / 30)
//                ctx.strokeStyle = colorSecondHand
//                ctx.fillStyle = colorSecondHand
//                ctx.lineWidth = 6.0
//                ctx.beginPath()
//                ctx.moveTo(-30.0, 0.0)
//                ctx.lineTo(83.0, 0.0)
//                ctx.stroke()
//                ctx.beginPath()
//                ctx.arc(0.0, 0.0, 10.0, 0.0, PI * 2, true)
//                ctx.fill()
//
//                // The loop at the end of the second hand
//                ctx.beginPath()
//                ctx.arc(95.0, 0.0, 10.0, 0.0, PI * 2, true)
//                ctx.stroke()
//                ctx.fillStyle = Colors.Transparent
//                ctx.arc(0.0, 0.0, 3.0, 0.0, PI * 2, true)
//                ctx.fill()
//            }
//
//            // The outer circle that frames the clock
//            ctx.beginPath()
//            ctx.lineWidth = 14.0
//            ctx.strokeStyle = colorBorder
//            ctx.arc(0.0, 0.0, 142.0, 0.0, PI * 2, true)
//            ctx.stroke()
        }
    }

