﻿package com.thwackstudio.utils

import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb

object Res {
    object Images {
        const val PROFILE_PHOTO = "images/profilephoto.png"
        const val THWACK_STUDIO_LOGO = "images/logo.png"
    }
    
    object Colors {
        val BG_BLACK = rgb(0,0,0)
        val BG_DARK = rgb(11,18,24)
        val BG_MIDDLE = rgb(16,29,38)
        val BG_LIGHT = rgb(25,42,55)
        val BG_BRIGHT = rgb(209,235,255)
        val BG_WHITE = rgb(255,255,255)


        val FONT_WHITE = rgb(255,255,255)
        val FONT_BLUISH_LIGHT = rgb(209,235,255)
        val FONT_ORANGE = rgb(192,98,76)
        val FONT_ORANGE_BRIGHT = rgb(255,111,105)
    }
    
    object Dimens {
        val MARGIN_SMALL = 10.px
        val MARGIN_MEDIUM = 20.px
        val MARGIN_LARGE = 30.px

        val FONT_SIZE_LARGE = 30.px
        val FONT_SIZE_MEDIUM = 20.px
        val FONT_SIZE_SMALL = 16.px
    }
}