package com.thwackstudio

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.theme.colors.palette.link
import org.jetbrains.compose.web.css.vh
import com.varabyte.kobweb.compose.ui.graphics.Color

@InitSilk
fun update(ctx: InitSilkContext) {
    val active = Color.rgb(255, 111, 105)
    val activated = Color.rgb(192,98,76)
    ctx.theme.palettes.light.link.set(active, activated)
//    ctx.theme.palettes.light.background = BRAND_LIGHT_BACKGROUND
//    ctx.theme.palettes.light.color = BRAND_LIGHT_COLOR
//    ctx.theme.palettes.dark.background = BRAND_DARK_BACKGROUND
//    ctx.theme.palettes.dark.color = BRAND_DARK_COLOR
}

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    SilkApp {
        Surface(SmoothColorStyle.toModifier().minHeight(100.vh)) {
            content()
        }
    }
}
